import { addAlpha, colors } from 'fitify-ui/src/theme/colors'
import { BREAKPOINTS } from 'fitify-ui-onboarding/src/utils/styled'
import { css, styled } from 'styled-components'

import { Stack } from '../Stack'

export const ButtonSizesConstants = ['sm', 'md', 'lg', 'xl'] as const

export type ButtonSize = (typeof ButtonSizesConstants)[number]

export interface ButtonStyledProps {
  size?: ButtonSize
  selected?: boolean
  isFixed?: boolean
  $isHovered?: boolean
}

export const StyledBaseButton = styled.button<ButtonStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--f-text);
  font-style: normal;
  text-align: center;
  font-weight: 500;

  border: none;
  border-radius: 48px;

  cursor: pointer;
  outline: none;

  width: 100%;
  max-width: 480px;

  padding: 10px 17px;
  margin: 0;
  gap: 5px;

  transition: all 0.3s ease;

  & img {
    pointer-events: none;
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }

  ${(props) => {
    switch (props.size) {
      case 'sm':
        return css`
          font-size: 0.875rem;
          min-height: 32px;
        `

      case 'md':
        return css`
          font-size: 0.875rem;
          min-height: 36px;
          width: max-content;
          min-width: 128px;
        `

      case 'lg':
        return css`
          font-size: 1rem;
          min-height: 64px;
        `

      case 'xl':
        return css`
          font-size: 1rem;
          min-height: 96px;
          max-height: 96px;
          padding: 0 17px;
        `
      default:
        return css`
          font-size: 1rem;
          line-height: 19px;
          min-height: 48px;
        `
    }
  }}
`

export const StyledPrimaryButton = styled(StyledBaseButton)`
  background: var(--c-primary);
  color: var(--c-primary-text);

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: var(--c-primary--hover);
    }
  }

  &:active {
    background: var(--c-primary--focus);
  }

  &[disabled] {
    background: var(--c-primary--disabled);
    color: var(--c-primary-color--disabled);
  }
`

export const StyledSecondaryButton = styled(StyledBaseButton)`
  background: var(--c-secondary);
  color: var(--c-secondary-text);

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: var(--c-secondary--hover);
    }
  }

  &:active {
    background: var(--c-secondary--focus);
  }

  &[disabled] {
    background: var(--c-secondary-disabled);
    color: var(--c--gray300);
  }
`

export const StyledWhiteButton = styled(StyledBaseButton)`
  background: var(--c-white);
  color: var(--c-black);
  border: 1px solid var(--c-gray200);

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: var(--c-gray200);
    }
  }

  &:active {
    background: var(--c-gray400);
  }

  &[disabled] {
    background: var(--c-gray300);
  }
`

export const StyledGrayButton = styled(StyledBaseButton)`
  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          background: var(--c-gray200);
          color: var(--c-black);

          @media (hover: hover) {
            &:hover,
            &:focus {
              background: var(--c-gray300);
            }
          }

          &:active {
            background: var(--c-white);
          }

          &[disabled] {
            background: var(--c-gray300);
            color: var(--c-gray400);
          }
        `
      default:
        return null
    }
  }}
`

export const StyledOutlineButton = styled(StyledBaseButton)`
  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          background: transparent;
          color: var(--c-black);
          border: 1px solid var(--c-gray200);

          @media (hover: hover) {
            &:hover {
              border-color: var(--c-blue-border);
            }
          }

          &:active {
            border-color: var(--c-blue-border);
          }
        `
      case props.theme.digital:
        return css`
          background: var(--c-blue-base);
          color: var(--c-blue75);
        `
      default:
        return null
    }
  }}
`

export const StyledPromoButton = styled(StyledBaseButton)`
  background: var(--c-promo);
  color: var(--c-promo-text);

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: var(--c-promo--hover);
    }
  }

  &:active {
    background: var(--c-promo--focus);
  }

  &[disabled] {
    background: var(--c-promo--disabled);
    color: var(--c-promo-color--disabled);
  }
`

/*
 ================ CUSTOM ================
*/

export const StyledSelectButton = styled(StyledBaseButton)`
  justify-content: space-between;
  background: var(--c-white);
  color: var(--c-black);
  border: 1px solid var(--c-gray200);
  border-radius: 12px;
  text-align: left;

  // Check icon
  &:after {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    min-width: 24px;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0 auto auto;
    border-radius: 12px;
    background: url('/icons/IconShape.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--c-blue400);
    z-index: 1;

    opacity: 0;
  }

  // Hovered but not active on mobile
  @media (hover: hover) {
    &:hover {
      background: var(--c-blue50);
      border-color: var(--c-blue400);
    }
  }

  &[disabled] {
    background: var(--c-gray300);
  }

  ${(props) => {
    if (props.selected) {
      return css`
        &,
        &:active {
          background: var(--c-white);
          border-color: var(--c-blue400);

          &:after {
            opacity: 1;
          }
        }

        @media ${BREAKPOINTS.MD.lte} {
          @media (hover: hover) {
            &:hover {
              border-color: var(--c-gray400);
            }
          }
        }
      `
    }

    if (props.$isHovered) {
      return css`
        @media ${BREAKPOINTS.MD.gte} {
          border-color: var(--c-gray400);
        }
      `
    }

    return null
  }}
`

export const StyledImageSelectButton = styled(StyledSelectButton)`
  height: 96px;
`

export const StyledTransparentSelectButton = styled(StyledSelectButton)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background: transparent !important;
  border: 2px solid var(--c-blue-border);
  color: var(--c-blue75);

  &:active {
    background: ${() => addAlpha(colors.Blue500, 0.16)};
    border-color: var(--c-vibrant-green);
    color: var(--c-white);
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--c-white);
    }
  }

  ${(props) => {
    if (props.selected) {
      return css`
        border-color: var(--c-vibrant-green);

        &,
        &:active {
          border-color: var(--c-vibrant-green);
          background: transparent;
          color: var(--c-white);

          &:after {
            background-image: url('/icons/IconShapeV2.svg');
            background-color: var(--c-vibrant-green);
            svg {
              path {
                fill: transparent;
              }
            }
            opacity: 1;
          }
        }

        @media (hover: hover) {
          &:hover {
            border-color: var(--c-vibrant-green);
            background: transparent;
          }
        }
      `
    }

    if (props.$isHovered) {
      return css`
        @media ${BREAKPOINTS.MD.gte} {
          background: transparent;
          border-color: var(--c-white);
          color: var(--c-white);
        }
      `
    }

    return null
  }}
`

export const StyledImageTransparentSelectButton = styled(
  StyledTransparentSelectButton
)`
  height: 96px;
  background: transparent;
  color: var(--c-blue75);
  padding-right: 0;
`

export const StyledSelectButtonCorner = styled(StyledSelectButton)`
  ${(props) => {
    if (props.selected) {
      return css`
        &,
        &:active {
          &:before {
            top: -8px;
            right: -8px;
            bottom: initial;
          }
        }
      `
    }

    return null
  }}
`

export const StyledOutlineSelectButton = styled(StyledOutlineButton)`
  justify-content: space-between;
  border-radius: 12px;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;

  &:active {
    background: ${() => addAlpha(colors.Blue500, 0.16)};
    border-color: var(--c-vibrant-green);
    color: var(--c-white);
  }

  ${(props) => {
    if (props.selected) {
      return css`
        &:before {
          content: '';
          position: absolute;
          height: 24px;
          width: 24px;
          right: -8px;
          top: -8px;
          border-radius: 50%;
          z-index: 1;
        }

        &:after {
          content: '';
          position: absolute;
          inset: -1px;
          border-radius: 12px;
          padding: 2px;
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      `
    }
    return null
  }}

  ${(props) => {
    if (props.theme.digital) {
      return css`
        color: var(--c-blue75);
        background: transparent;
        border: 2px solid var(--c-blue-border);

        @media (hover: hover) {
          &:hover {
            color: var(--c-white);
            border-color: var(--c-white);
          }
        }

        ${props.selected &&
        css`
          color: var(--c-white);

          @media (hover: hover) {
            &:hover,
            &:focus {
              border-color: transparent;
            }
          }

          &:before {
            background: url('/icons/IconShapeV2.svg');
            background-color: var(--c-vibrant-green);
            background-repeat: no-repeat;
            background-position: center center;
          }

          &:after {
            background: linear-gradient(180deg, #efff33 0%, #9df82a 100%);
          }
        `}
      `
    }

    return css`
      background-color: var(--c-white);

      ${props.selected &&
      css`
        @media (hover: hover) {
          &:hover,
          &:focus {
            border-color: transparent;
          }
        }

        &:before {
          background: url('/icons/IconShape.svg');
          background-color: var(--c-blue400);
          background-repeat: no-repeat;
          background-position: center center;
        }

        &:after {
          background: var(--c-blue400);
        }
      `}
    `
  }}
`

export const AuthSocialIcon = styled.span`
  position: relative;
  height: 20px;
  padding-right: 9px;
  bottom: 1px;
`

export const StyledCircleButton = styled.button<{
  isHidden?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;

  padding: 0;
  font-family: inherit;
  background: transparent;
  border: 0;
  border-radius: 100%;
  outline: none;
  cursor: pointer;

  background: var(--c-gray200);

  &:hover {
    background-color: var(--c-gray300);
  }

  ${(props) => {
    if (props.theme.digital) {
      return css`
        background: var(--c-blue850);

        svg {
          path {
            color: var(--c-white);
          }
        }

        &:hover {
          background-color: var(--c-blue850);
        }

        @media ${BREAKPOINTS.MD.lte} {
          position: fixed;
          top: 14px;
          width: 36px;
          height: 36px;
          z-index: 999;
        }
      `
    }
    return css`
      @media ${BREAKPOINTS.MD.lte} {
        width: 32px;
        height: 32px;
      }
    `
  }}

  ${(props) => {
    if (props.isHidden) {
      return css`
        display: none;
        opacity: 0;
        pointer-events: none;
      `
    }
    return null
  }}
`

export const StyledButtonImageWrapper = styled(Stack)`
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;

  img {
    max-height: 100%;
  }
`
