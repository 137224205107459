import { styled, css, type RuleSet } from 'styled-components'

import { BREAKPOINTS, colorOpacity } from '../../utils/styled'
import { Stack } from '../Stack'

export const SubscriptionPromoInfoBoxStyled = styled.div`
  position: relative;
  max-width: var(--w-content);
  width: 100%;
  margin: 0 auto 16px;
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--c-yellow300);
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid;
  border-color: var(--c-yellow300);

  font-family: 'Graphik LCG Web';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
`

export const LayoutContent = styled.div<{
  $isFullscreen?: boolean
  $isPromoActive?: boolean
  $hasShadow?: boolean
  $hasBackground?: boolean
  $layoutStyle?: RuleSet<object>
  $variant?: 'subscription' | 'default'
}>`
  position: relative;
  width: 100%;
  min-height: 600px;
  max-width: var(--w-content-max-width);
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  min-height: calc(100dvh - var(--h-header));
  margin-top: var(--h-header);
  padding: ${(props) =>
    props.theme.digital ? '2rem 2rem 0' : '2rem 2rem 1rem'};

  @media ${BREAKPOINTS.MD.lte} {
    max-width: 100%;
    min-height: calc(100dvh - var(--h-header-mobile));
    margin-top: var(--h-header-mobile);
    padding: ${(props) =>
      props.theme.digital ? '1rem 1.25rem 0' : '1rem 1.25rem'};
  }

  /* THEME CUSTOMIZATION */
  ${(props) => {
    switch (true) {
      case props.$hasBackground:
        return css`
          min-height: 700px;
          margin-top: calc(var(--h-header) + 2rem);
          margin-bottom: 2rem;
          padding: ${props.$isPromoActive ? '5rem' : '3.5rem'} 2rem 2rem;
          background-color: ${colorOpacity('var(--c-blue-base)', 85)};
          overflow: hidden;

          @media ${BREAKPOINTS.MD.lte} {
            min-height: calc(100dvh - var(--h-header-mobile));
            margin: var(--h-header-mobile) 0 0;
            padding: ${props.$isPromoActive ? '5rem 1rem 1rem' : '2rem 1.5rem'};
            border-radius: 0;
          }
        `
      default:
        return null
    }
  }}

  ${(props) =>
    props.theme.digital &&
    props.$hasBackground &&
    css`
      background-color: var(--c-blue-base);
    `}

  /* SHADOW */
  ${({ $hasShadow }) => {
    if ($hasShadow) {
      return css`
        margin-top: calc(var(--h-header) + 2rem);
        box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
      `
    }

    return null
  }}

  /* SHARED PARAMS */
  ${(props) => {
    if (props.$isFullscreen) {
      return css`
        width: 100%;
        height: calc(100dvh - var(--h-header));
        min-height: initial;
        max-width: initial;
        margin: var(--h-header) 0 0;
        padding: 0;

        @media ${BREAKPOINTS.MD.lte} {
          height: calc(100dvh - var(--h-header-mobile));
          margin: var(--h-header-mobile) 0 0;
          padding: 0;
        }
      `
    }

    return null
  }}

  ${(props) => props.$layoutStyle}

  ${(props) =>
    props.$variant === 'subscription' &&
    css`
      padding: 3.75rem;
      padding-bottom: 0;
      @media ${BREAKPOINTS.MD.lte} {
        padding: 0;
      }
    `}
`

export const LayoutStack = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  @media ${BREAKPOINTS.LG.lte} {
    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`
