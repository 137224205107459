import { User } from 'fitify-types/src/types/user'

import { IDataState } from 'utils/state'

export enum CharacterStyle {
  Illustrated = 'illustrated',
  Memoji = 'memoji',
  Realistic = 'realistic',
}

export const DEFAULT_CHARACTER_STYLE = CharacterStyle.Illustrated

export const PROBLEM_AREAS_MALE = [
  User.Profile.ProblemArea.Arms,
  User.Profile.ProblemArea.Chest,
  User.Profile.ProblemArea.Belly,
  User.Profile.ProblemArea.Thighs,
]

export const PROBLEM_AREAS_FEMALE = [
  User.Profile.ProblemArea.Arms,
  User.Profile.ProblemArea.Belly,
  User.Profile.ProblemArea.Butt,
  User.Profile.ProblemArea.Thighs,
]

export type GoalPageProps = {
  t: (key: string) => string
  data: IDataState
  actions: {
    handleContinue: (selected: User.Profile.Goals) => void
  }
  characterStyle?: CharacterStyle
}
export type PageAreaProps = {
  data: IDataState
  actions: { handleProblemAreaChange: (area: User.Profile.ProblemArea) => void }
  characterStyle?: CharacterStyle
}

export type BodyPageProps = {
  t: (key: string) => string
  data: IDataState
  actions: {
    handleContinue: (selected: User.Profile.BodyType) => void
  }
  characterStyle?: CharacterStyle
}

export type GenderPageProps = {
  t: (key: string) => string
  data: IDataState
  actions: { handleContinue: (selected: User.Profile.Gender) => void }
  characterStyle?: CharacterStyle
}

export type HomePageProps = {
  t: (key: string) => string
  initialLocale?: string
  actions: {
    handleContinue: () => void
    handleLogin: () => void
  }
  characterStyle?: CharacterStyle
}
