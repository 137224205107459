import { Text } from 'fitify-ui-onboarding/src/components'
import { HTMLAttributes, ReactNode } from 'react'

import { StyledFooter, StyledDesktopDisclaimer } from './Footer.Styled'

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  disclamer: ReactNode
}

export const Footer = ({ disclamer }: FooterProps) => {
  return (
    <StyledFooter>
      <Text $size="xs">
        &copy; Fitify Workouts s.r.o., {new Date().getFullYear()}
        <StyledDesktopDisclaimer>
          <br />
          {disclamer}
        </StyledDesktopDisclaimer>
      </Text>
    </StyledFooter>
  )
}
