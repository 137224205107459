import {
  getAuth,
  onAuthStateChanged,
  signOut,
  type User as AuthUser,
} from 'firebase/auth'
import { User } from 'fitify-types/src/types/user'
import {
  cancellablePromise,
  isPromiseCancelledError,
} from 'fitify-utils/src/async/cancellable-promise'
import { useRouter } from 'next/router'
import {
  Context,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { fetchUserProfile } from '../..'

export type UserAuthData = {
  user: AuthUser | null
  userProfile: User | null
  isLoggedIn: boolean
  isAuthLoading: boolean
  logout: () => Promise<void>
  refreshUserProfile: () => Promise<void>
}

export const UserContext: Context<UserAuthData> = createContext<UserAuthData>({
  user: null,
  userProfile: null,
  isLoggedIn: false,
  isAuthLoading: true,
  logout: async () => Promise.resolve(undefined),
  refreshUserProfile: async () => Promise.resolve(undefined),
})

export const UserContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<AuthUser | null>(null)
  const previousUserRef = useRef<AuthUser | null>(null)
  const fetchUserProfilePromiseController = useRef<AbortController | undefined>(
    undefined
  )
  const [userProfile, setUserProfile] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAuthStateLoaded, setIsAuthStateLoaded] = useState<boolean>(false)

  const router = useRouter()

  const cleanContext = useCallback(() => {
    setUser(null)
    setUserProfile(null)
    previousUserRef.current = null
    setIsLoading(false)
  }, [])

  const fetchProfile = useCallback(async (userId: string) => {
    try {
      fetchUserProfilePromiseController.current?.abort()
      fetchUserProfilePromiseController.current = new AbortController()

      setIsLoading(true)
      const profile = await cancellablePromise(
        fetchUserProfile(userId),
        fetchUserProfilePromiseController.current.signal
      )
      setUserProfile(profile)
      setIsLoading(false)
    } catch (error) {
      if (!isPromiseCancelledError(error)) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    return onAuthStateChanged(getAuth(), (authUser) => {
      if (authUser) {
        setUser(authUser)
        if (authUser.uid !== previousUserRef.current?.uid) {
          void fetchProfile(authUser.uid)
          previousUserRef.current = authUser
        }
      } else {
        // Reset state if no user is authenticated
        cleanContext()
      }
      setIsAuthStateLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  const refreshUserProfile = useCallback(async () => {
    if (user) {
      await fetchProfile(user.uid)
    }
  }, [fetchProfile, user])

  const value = useMemo(
    () => ({
      user,
      userProfile,
      isLoggedIn: !!user,
      isAuthLoading: isLoading || !isAuthStateLoaded,
      logout: async () => {
        await signOut(getAuth())
        cleanContext()
      },
      refreshUserProfile,
    }),
    [
      cleanContext,
      isAuthStateLoaded,
      isLoading,
      refreshUserProfile,
      user,
      userProfile,
    ]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}
