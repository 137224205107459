import { colors } from 'fitify-ui/src/theme/colors'

import { Stack } from '../Stack'

import { StyledSpinnerSvg } from './Spinner.Styled'

const DEFAULT_DURATION = 1.4
const DEFAULT_SIZE = 'md'
const DEFAULT_COLOR = colors.Gray400
const DEFAULT_ALIGN = 'center'
const SIZE_MAP = {
  sm: [24, 24, 72, 3],
  md: [66, 66, 200, 6],
  lg: [90, 90, 280, 6],
}

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg'
  align?: 'flex-start' | 'center' | 'flex-end'
  color?: string
  duration?: number
}

export function Spinner({
  size = DEFAULT_SIZE,
  align = DEFAULT_ALIGN,
  color = DEFAULT_COLOR,
  duration = DEFAULT_DURATION,
}: SpinnerProps) {
  const [width, height, offset, stroke] = SIZE_MAP[size]

  return (
    <Stack
      style={{
        alignItems: align,
      }}
    >
      <StyledSpinnerSvg
        $offset={offset}
        $duration={duration}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: color }}
      >
        <circle
          fill="none"
          strokeWidth={stroke}
          strokeLinecap="round"
          stroke="currentColor"
          cx={width / 2}
          cy={height / 2}
          r={width / 2 - 3}
        />
      </StyledSpinnerSvg>
    </Stack>
  )
}
