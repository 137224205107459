import { css, styled } from 'styled-components'

import { BREAKPOINTS } from '../utils/styled'

import { Stack } from './Stack'

export const HeadingContainer = styled(Stack)`
  max-width: var(--w-content);
  align-items: left;
`

export const ContentContainer = styled(Stack)<{ removePadding?: boolean }>`
  flex: 1;
  max-width: var(--w-content);

  ${({ removePadding }) =>
    !removePadding &&
    css`
      padding-top: 2rem;

      @media ${BREAKPOINTS.MD.lte} {
        padding-top: 1.25rem;
      }
    `}
`

export const PageContainer = styled(Stack)<{
  variant?: 'default' | 'subscription'
}>`
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  max-width: var(--w-content-wide);

  @media ${BREAKPOINTS.MD.lte} {
    max-width: var(--w-content);

    ${({ variant }) =>
      variant === 'subscription' &&
      css`
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      `}
  }
`

export const FormContainer = styled.form`
  position: relative;
  width: 100%;

  flex: 1;
  max-width: var(--w-content);

  display: flex;
  break-inside: avoid;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => {
    if (props.theme.hcV1) {
      return css`
        padding-top: 4rem;

        @media ${BREAKPOINTS.MD.lte} {
          padding-top: 1.25rem;
        }
      `
    } else {
      return css`
        padding-top: 2rem;

        @media ${BREAKPOINTS.MD.lte} {
          padding-top: 1.25rem;
        }
      `
    }
  }}
`
