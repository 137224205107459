import { logEvent } from 'fitify-ui/src/utils/analytics'
import { useEffect } from 'react'

import { IRouteType } from '../types'

import { useRouter } from './useRouter'

/**
 * Validates the user's progress through a multi-step form by checking if they have completed the previous steps.
 * @function
 * @param {IRouteType<string>[]} routes - An array of route objects containing information about each step in the form.
 */
export const useStepValidation = (
  routes: IRouteType<string>[],
  data: Record<string, any>,
  isLoading: boolean
) => {
  const router = useRouter()

  useEffect(() => {
    // Run this function after routes
    if (!routes) return

    // wait until session storage is loaded
    if (isLoading) return

    const path = router.pathname
    // Get the stored session data
    const currentStepIndex = routes.findIndex((route) => route.path === path)

    if (!routes[currentStepIndex]?.isValidatedStep) return

    // Steps that are able to be validated
    const stepRoutes = routes.slice(0, currentStepIndex).filter((route) => {
      if (route.key) {
        if (route.condition) {
          return route.condition(data)
        } else {
          return true
        }
      }

      return false
    })
    const keysToValidate = stepRoutes.map((route) => route.key) as string[]

    // Get the keys of the stored data and the keys of the completed steps
    const storeKeys = Object.keys(data)

    // If the user has not completed the previous steps
    // redirect them to last missing step
    if (storeKeys.length > 0) {
      for (let i = 0; i < keysToValidate.length; i++) {
        if (!storeKeys.includes(keysToValidate[i])) {
          const missedStep = routes.find(
            (route) => route.key === keysToValidate[i]
          ) as IRouteType<string>

          logEvent('onboarding_step_validation_redirect', {
            current_step: path,
            missed_step: missedStep?.key,
          })

          void router.replace(missedStep?.path)
          break
        }
      }
    }

    // Check if user has no stored data and step is after gender step
    if (currentStepIndex > 1 && storeKeys.length === 0) {
      void router.replace('/')
    }
  }, [router, routes, data, isLoading])
}
