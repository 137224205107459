import isPropValid from '@emotion/is-prop-valid'
import { isNullOrUndefined } from 'fitify-utils/src/properties'
import { styled } from 'styled-components'

import { BREAKPOINTS } from '../utils/styled'

export type StackAlignment =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'

export const Stack = styled('div').withConfig({
  shouldForwardProp: (prop) =>
    isPropValid(prop as string) && prop !== 'orientation',
})<{
  orientation?: 'horizontal' | 'vertical'
  xs?: number | string
  sm?: number | string
  sm2?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  align?: StackAlignment
  justify?: StackAlignment
}>`
  position: relative;
  width: 100%;
  display: flex;
  break-inside: avoid;
  flex-direction: ${({ orientation = 'vertical' }) => {
    switch (orientation) {
      case 'horizontal':
        return 'row'
      case 'vertical':
      default:
        return 'column'
    }
  }};

  align-items: ${({ align = 'initial' }) => align};
  justify-content: ${({ justify = 'initial' }) => justify};

  & > *:not(:last-child) {
    ${({ orientation = 'vertical', xs, sm, sm2, md, lg, xl }) => {
      let result = ''
      for (const [key, value] of Object.entries({
        XS: xs,
        SM: sm,
        SM2: sm2,
        MD: md,
        LG: lg,
        XL: xl,
      })) {
        if (isNullOrUndefined(value)) continue

        result += `@media ${BREAKPOINTS[key as keyof typeof BREAKPOINTS].gte} {
          ${
            (orientation === 'horizontal' && 'margin-right') ||
            (orientation === 'vertical' && 'margin-bottom') ||
            undefined
          }: ${typeof value === 'string' ? value : `${value}px`};
        }`
      }
      return result
    }}
  }
`
