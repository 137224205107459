import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { toBoolean } from 'fitify-utils/src/properties'
import { useRouter } from 'next/router'
import {
  Context,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ThemeContext } from 'styled-components'

import { PlanInterval } from '../types/subscription'
import { clearItem, getItem, setItem } from '../utils'

import { useUserContext } from './UserContext'

export const ACTIVE_PROMO_STORE = '@fitify/subscription/activePromo'
const PLAN_TYPES = Object.values(PlanInterval)

export const PromoContext: Context<string | null | undefined> = createContext<
  string | null | undefined
>(undefined)

export const PromoContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter()
  const [activePromo, setActivePromo] = useState<string | null>(
    getItem('local', ACTIVE_PROMO_STORE) ||
      router.query.promo?.toString() ||
      null
  )
  const [isPromoDeactivated, setIsPromoDeactivated] = useState<boolean>(false)

  const config = useContext(RemoteContext)
  const theme = useContext(ThemeContext)
  const appPrefix = useMemo(() => (theme?.hcV1 ? '_coach' : ''), [theme])
  const { isLoggedIn } = useUserContext()

  useEffect(() => {
    if (!router.isReady || isPromoDeactivated) return

    const queryPromo = router.query.promo as string
    const storedPromo = getItem('local', ACTIVE_PROMO_STORE)

    // Update active promo and local storage only if query promo is different and not empty
    if (queryPromo && queryPromo !== storedPromo) {
      setActivePromo(queryPromo)
      setItem('local', ACTIVE_PROMO_STORE, queryPromo)
    } else if (storedPromo) {
      setActivePromo(storedPromo)
    } else if (isLoggedIn && !theme?.hcV1) {
      // Set default promo from remote config
      setActivePromo(config?.['onboarding_promo'] || null)
      setItem('local', ACTIVE_PROMO_STORE, config?.['onboarding_promo'] || null)
    }
  }, [
    config,
    isLoggedIn,
    isPromoDeactivated,
    router.isReady,
    router.query.promo,
    theme?.hcV1,
  ])

  const deactivatePromo = useCallback(() => {
    setIsPromoDeactivated(true)
    setActivePromo(null)
    const storedPromo = getItem('local', ACTIVE_PROMO_STORE)
    if (storedPromo) {
      clearItem('local', ACTIVE_PROMO_STORE)
    }
  }, [])

  // Deactivate promo for HC version
  useEffect(() => {
    if (theme?.hcV1) {
      deactivatePromo()
    }
  }, [deactivatePromo, theme?.hcV1])

  const promoCode = useMemo(() => {
    if (!activePromo || isPromoDeactivated) return null

    const promoEnabled = PLAN_TYPES.some((plan) =>
      toBoolean(config?.[`promo_${activePromo}_${plan}${appPrefix}_enabled`])
    )

    return promoEnabled ? activePromo : null
  }, [activePromo, isPromoDeactivated, config, appPrefix])

  return (
    <PromoContext.Provider value={promoCode}>{children}</PromoContext.Provider>
  )
}

export const usePromoContext = () => {
  const context = useContext(PromoContext)
  if (context === undefined) {
    throw new Error(
      'usePromoContext must be used within a PromoContextProvider'
    )
  }
  return context
}
