import { User } from 'fitify-types/src/types/user'

const ONBOARDING_IMAGES = {
  common: {
    logo: {
      width: 122,
      height: 38,
      srcSet: ['/images/logo.webp', '/images/logo@2x.webp'],
    },
  },
  home: {
    youngCoupleShape: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/onboarding/youngCoupleShapeV2.webp',
        '/images/onboarding/youngCoupleShapeV2@2x.webp',
      ],
    },
    youngCoupleShapeOld: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/youngCoupleShape.webp',
        '/images/onboarding/youngCoupleShape@2x.webp',
      ],
    },
    youngCoupleFullLocalized: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/localized/welcome_full/welcome_full_{{locale}}.webp',
        '/images/onboarding/localized/welcome_full/welcome_full_{{locale}}@2x.webp',
      ],
    },
    youngCoupleCroppedLocalized: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/localized/welcome_cropped/welcome_cropped_{{locale}}.webp',
        '/images/onboarding/localized/welcome_cropped/welcome_cropped_{{locale}}@2x.webp',
      ],
    },
    youngCoupleMemojiLocalized: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/onboarding/localized/welcome_memoji/welcome_memoji_mockups_{{locale}}.webp',
        '/images/onboarding/localized/welcome_memoji/welcome_memoji_mockups_{{locale}}@2x.webp',
      ],
    },
    youngCoupleRealisticLocalized: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/onboarding/localized/welcome_realistic/welcome_realistic_mockups_{{locale}}.webp',
        '/images/onboarding/localized/welcome_realistic/welcome_realistic_mockups_{{locale}}@2x.webp',
      ],
    },
    youngCoupleShapeDark: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/youngCoupleShapeDark.webp',
        '/images/onboarding/youngCoupleShapeDark@2x.webp',
      ],
    },
  },
  gender: {
    genderFemale: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_female.webp',
        '/images/onboarding/gender/gender_female@2x.webp',
      ],
    },
    genderFemaleMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_female_memoji.webp',
        '/images/onboarding/gender/gender_female_memoji@2x.webp',
      ],
    },
    genderFemaleRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_female_realistic.webp',
        '/images/onboarding/gender/gender_female_realistic@2x.webp',
      ],
    },
    genderMale: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_male.webp',
        '/images/onboarding/gender/gender_male@2x.webp',
      ],
    },
    genderMaleMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_male_memoji.webp',
        '/images/onboarding/gender/gender_male_memoji@2x.webp',
      ],
    },
    genderMaleRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/gender/gender_male_realistic.webp',
        '/images/onboarding/gender/gender_male_realistic@2x.webp',
      ],
    },
  },
  goal: {
    goalFemaleToned: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/illustrated/goal_female_toned.webp',
        '/images/onboarding/goal/illustrated/goal_female_toned@2x.webp',
      ],
    },
    goalFemaleTonedRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_female_toned_realistic.webp',
        '/images/onboarding/goal/realistic/goal_female_toned_realistic@2x.webp',
      ],
    },
    goalFemaleTonedMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_female_toned_memoji.webp',
        '/images/onboarding/goal/memoji/goal_female_toned_memoji@2x.webp',
      ],
    },
    goalFemaleWeightRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_female_weight_realistic.webp',
        '/images/onboarding/goal/realistic/goal_female_weight_realistic@2x.webp',
      ],
    },
    goalFemaleWeightMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_female_weight_memoji.webp',
        '/images/onboarding/goal/memoji/goal_female_weight_memoji@2x.webp',
      ],
    },
    goalWeight: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/illustrated/goal_weight.webp',
        '/images/onboarding/goal/illustrated/goal_weight@2x.webp',
      ],
    },
    goalMaleWeightRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_male_weight_realistic.webp',
        '/images/onboarding/goal/realistic/goal_male_weight_realistic@2x.webp',
      ],
    },
    goalMaleWeightMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_male_weight_memoji.webp',
        '/images/onboarding/goal/memoji/goal_male_weight_memoji@2x.webp',
      ],
    },
    goalMaleMuscle: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/illustrated/goal_male_muscle.webp',
        '/images/onboarding/goal/illustrated/goal_male_muscle@2x.webp',
      ],
    },
    goalMaleMuscleRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_male_muscle_realistic.webp',
        '/images/onboarding/goal/realistic/goal_male_muscle_realistic@2x.webp',
      ],
    },
    goalMaleMuscleMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_male_muscle_memoji.webp',
        '/images/onboarding/goal/memoji/goal_male_muscle_memoji@2x.webp',
      ],
    },
    goalFemaleFitter: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/illustrated/goal_female_fitter.webp',
        '/images/onboarding/goal/illustrated/goal_female_fitter@2x.webp',
      ],
    },
    goalFemaleFitterRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_female_fitter_realistic.webp',
        '/images/onboarding/goal/realistic/goal_female_fitter_realistic@2x.webp',
      ],
    },
    goalFemaleFitterMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_female_fitter_memoji.webp',
        '/images/onboarding/goal/memoji/goal_female_fitter_memoji@2x.webp',
      ],
    },
    goalMaleFitter: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/illustrated/goal_male_fitter.webp',
        '/images/onboarding/goal/illustrated/goal_male_fitter@2x.webp',
      ],
    },
    goalMaleFitterRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/realistic/goal_male_fitter_realistic.webp',
        '/images/onboarding/goal/realistic/goal_male_fitter_realistic@2x.webp',
      ],
    },
    goalMaleFitterMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/goal/memoji/goal_male_fitter_memoji.webp',
        '/images/onboarding/goal/memoji/goal_male_fitter_memoji@2x.webp',
      ],
    },
  },
  body: {
    bodyFemaleHourglass: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_female_hourglass.webp',
        '/images/onboarding/body/illustrated/body_female_hourglass@2x.webp',
      ],
    },
    bodyFemaleHourglassMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_female_hourglass_memoji.webp',
        '/images/onboarding/body/memoji/body_female_hourglass_memoji@2x.webp',
      ],
    },
    bodyFemaleHourglassRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_female_hourglass_realistic.webp',
        '/images/onboarding/body/realistic/body_female_hourglass_realistic@2x.webp',
      ],
    },
    bodyFemaleRectangle: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_female_rectangle.webp',
        '/images/onboarding/body/illustrated/body_female_rectangle@2x.webp',
      ],
    },
    bodyFemaleRectangleMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_female_rectangle_memoji.webp',
        '/images/onboarding/body/memoji/body_female_rectangle_memoji@2x.webp',
      ],
    },
    bodyFemaleRectangleRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_female_rectangle_realistic.webp',
        '/images/onboarding/body/realistic/body_female_rectangle_realistic@2x.webp',
      ],
    },
    bodyFemaleRounded: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_female_rounded.webp',
        '/images/onboarding/body/illustrated/body_female_rounded@2x.webp',
      ],
    },
    bodyFemaleRoundedMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_female_rounded_memoji.webp',
        '/images/onboarding/body/memoji/body_female_rounded_memoji@2x.webp',
      ],
    },
    bodyFemaleRoundedRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_female_rounded_realistic.webp',
        '/images/onboarding/body/realistic/body_female_rounded_realistic@2x.webp',
      ],
    },
    bodyFemaleLightbulb: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_female_lightbulb.webp',
        '/images/onboarding/body/illustrated/body_female_lightbulb@2x.webp',
      ],
    },
    bodyFemaleLightbulbMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_female_lightbulb_memoji.webp',
        '/images/onboarding/body/memoji/body_female_lightbulb_memoji@2x.webp',
      ],
    },
    bodyFemaleLightbulbRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_female_lightbulb_realistic.webp',
        '/images/onboarding/body/realistic/body_female_lightbulb_realistic@2x.webp',
      ],
    },
    bodyMaleSkinny: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_male_skinny.webp',
        '/images/onboarding/body/illustrated/body_male_skinny@2x.webp',
      ],
    },
    bodyMaleSkinnyMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_male_skinny_memoji.webp',
        '/images/onboarding/body/memoji/body_male_skinny_memoji@2x.webp',
      ],
    },
    bodyMaleSkinnyRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_male_skinny_realistic.webp',
        '/images/onboarding/body/realistic/body_male_skinny_realistic@2x.webp',
      ],
    },
    bodyMaleIdeal: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_male_ideal.webp',
        '/images/onboarding/body/illustrated/body_male_ideal@2x.webp',
      ],
    },
    bodyMaleIdealMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_male_ideal_memoji.webp',
        '/images/onboarding/body/memoji/body_male_ideal_memoji@2x.webp',
      ],
    },
    bodyMaleIdealRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_male_ideal_realistic.webp',
        '/images/onboarding/body/realistic/body_male_ideal_realistic@2x.webp',
      ],
    },
    bodyMaleFlabby: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_male_flabby.webp',
        '/images/onboarding/body/illustrated/body_male_flabby@2x.webp',
      ],
    },
    bodyMaleFlabbyMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_male_flabby_memoji.webp',
        '/images/onboarding/body/memoji/body_male_flabby_memoji@2x.webp',
      ],
    },
    bodyMaleFlabbyRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_male_flabby_realistic.webp',
        '/images/onboarding/body/realistic/body_male_flabby_realistic@2x.webp',
      ],
    },
    bodyMaleHeavier: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/illustrated/body_male_heavier.webp',
        '/images/onboarding/body/illustrated/body_male_heavier@2x.webp',
      ],
    },
    bodyMaleHeavierMemoji: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/memoji/body_male_heavier_memoji.webp',
        '/images/onboarding/body/memoji/body_male_heavier_memoji@2x.webp',
      ],
    },
    bodyMaleHeavierRealistic: {
      width: 160,
      height: 96,
      srcSet: [
        '/images/onboarding/body/realistic/body_male_heavier_realistic.webp',
        '/images/onboarding/body/realistic/body_male_heavier_realistic@2x.webp',
      ],
    },
    maleArea: {
      width: 168,
      height: 430,
      srcSet: [
        '/images/onboarding/body/illustrated/maleArea.webp',
        '/images/onboarding/body/illustrated/maleArea@2x.webp',
      ],
    },
    maleAreaRealistic: {
      width: 200,
      height: 400,
      srcSet: [
        '/images/onboarding/body/realistic/male_area_realistic.webp',
        '/images/onboarding/body/realistic/male_area_realistic@2x.webp',
      ],
    },
    maleAreaMemoji: {
      width: 200,
      height: 400,
      srcSet: [
        '/images/onboarding/body/memoji/male_area_memoji.webp',
        '/images/onboarding/body/memoji/male_area_memoji@2x.webp',
      ],
    },
    femaleArea: {
      width: 168,
      height: 430,
      srcSet: [
        '/images/onboarding/body/illustrated/femaleArea.webp',
        '/images/onboarding/body/illustrated/femaleArea@2x.webp',
      ],
    },
    femaleAreaRealistic: {
      width: 200,
      height: 400,
      srcSet: [
        '/images/onboarding/body/realistic/female_area_realistic.webp',
        '/images/onboarding/body/realistic/female_area_realistic@2x.webp',
      ],
    },
    femaleAreaMemoji: {
      width: 200,
      height: 400,
      srcSet: [
        '/images/onboarding/body/memoji/female_area_memoji.webp',
        '/images/onboarding/body/memoji/female_area_memoji@2x.webp',
      ],
    },
  },
  plan: {
    male: {
      width: 135,
      height: 319,
      srcSet: [
        '/images/onboarding/malePlan.webp',
        '/images/onboarding/malePlan@2x.webp',
      ],
    },
    female: {
      width: 135,
      height: 319,
      srcSet: [
        '/images/onboarding/femalePlan.webp',
        '/images/onboarding/femalePlan@2x.webp',
      ],
    },
  },
  download: {
    phones: {
      width: 389,
      height: 255,
      srcSet: ['/images/download/phones.webp'],
      alt: 'Fitify applications',
    },
  },
  subscription: {
    phones: {
      width: 275,
      height: 419,
      srcSet: [
        '/images/subscription/phones.webp',
        '/images/subscription/phones@2x.webp',
      ],
    },
    paywallDesktopLocalized: {
      width: 300,
      height: 422,
      srcSet: [
        '/images/subscription/localized/paywall_desktop/paywall_desktop_{{locale}}.webp',
        '/images/subscription/localized/paywall_desktop/paywall_desktop_{{locale}}@2x.webp',
      ],
    },
    paywallMobileLocalized: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/illustrated/paywall_mobile_illustrated_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/illustrated/paywall_mobile_illustrated_{{locale}}@2x.webp',
      ],
    },
    paywallMobileMjRealisticLocalized: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/mj_realistic/paywall_mj_realistic_couple_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/mj_realistic/paywall_mj_realistic_couple_{{locale}}@2x.webp',
      ],
    },
    paywallMobileWorkoutCategoriesLocalized: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/workout_categories/realistic_workout_categories_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/workout_categories/realistic_workout_categories_{{locale}}@2x.webp',
      ],
    },
    paywallMobileMemojiLocalized: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/memoji/paywall_mobile_memoji_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/memoji/paywall_mobile_memoji_{{locale}}@2x.webp',
      ],
    },
    paywallMobileRealisticLocalized: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/realistic/paywall_mobile_realistic_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/realistic/paywall_mobile_realistic_{{locale}}@2x.webp',
      ],
    },
    paywallMobile: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/payment_header_shutterstock_jun2021.webp',
        '/images/subscription/payment_header_shutterstock_jun2021@2x.webp',
      ],
    },
    specialOfferGift: {
      width: 216,
      height: 216,
      srcSet: [
        '/images/subscription/gift_voucher.webp',
        '/images/subscription/gift_voucher@2x.webp',
      ],
    },
    specialOfferFriendGift: {
      width: 216,
      height: 216,
      srcSet: [
        '/images/subscription/promo_gift.webp',
        '/images/subscription/promo_gift@2x.webp',
      ],
    },
    specialOffer: {
      width: 240,
      height: 126,
      srcSet: [
        '/images/subscription/special_offer.webp',
        '/images/subscription/special_offer@2x.webp',
      ],
    },
    payment_header_shutterstock_jun2021: {
      width: 750,
      height: 376,
      srcSet: [
        '/images/subscription/payment_header_shutterstock_jun2021.webp',
        '/images/subscription/payment_header_shutterstock_jun2021@2x.webp',
      ],
    },
    isic: {
      width: 80,
      height: 80,
      srcSet: ['/images/subscription/isic_logo.svg'],
    },
    itic: {
      width: 80,
      height: 80,
      srcSet: ['/images/subscription/itic_logo.svg'],
    },
  },
  gift: {
    mobileMockup: {
      width: 300,
      height: 245,
      srcSet: [
        '/images/gift/gift-mobile-mockup.webp',
        '/images/gift/gift-mobile-mockup@2x.webp',
      ],
    },
  },
  salesPitch: {
    loseWeightF: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/female-lose-weight.webp',
        '/images/pitch/female-lose-weight@2x.webp',
      ],
    },
    getTonedF: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/female-get-toned.webp',
        '/images/pitch/female-get-toned@2x.webp',
      ],
    },
    loseWeightM: {
      width: 480,
      height: 320,
      srcSet: [
        '/images/pitch/male-lose-weight.webp',
        '/images/pitch/male-lose-weight@2x.webp',
      ],
    },
    buildMuscleM: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/male-build-muscle.webp',
        '/images/pitch/male-build-muscle@2x.webp',
      ],
    },
    weightChart: {
      width: 480,
      height: 196,
      srcSet: [
        '/images/pitch/weight-curve-v3.webp',
        '/images/pitch/weight-curve-v3@2x.webp',
      ],
    },
    fitnessChart: {
      width: 480,
      height: 258,
      srcSet: [
        '/images/pitch/fitness-chart.webp',
        '/images/pitch/fitness-chart@2x.webp',
      ],
    },
    kneePain: {
      width: 480,
      height: 278,
      srcSet: [
        '/images/pitch/knee-pitch.webp',
        '/images/pitch/knee-pitch@2x.webp',
      ],
    },
    motivation: {
      width: 316,
      height: 196,
      srcSet: [
        '/images/pitch/pitch-motivation-v3.webp',
        '/images/pitch/pitch-motivation-v3@2x.webp',
      ],
    },
    commitment: {
      width: 332,
      height: 196,
      srcSet: [
        '/images/pitch/pitch-commitment-v3.webp',
        '/images/pitch/pitch-commitment-v3@2x.webp',
      ],
    },
  },
  leadingImage: {
    improveHealth: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_improve_health_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_improve_health_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
    moreRelaxed: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_more_relaxed_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_more_relaxed_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
    sleepBetter: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_sleep_better_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_sleep_better_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
  },
} as const satisfies Record<string, Record<string, OnboardingImage>>

type OnboardingImageKeys = keyof typeof ONBOARDING_IMAGES
type OnboardingImages<Page extends OnboardingImageKeys> =
  keyof (typeof ONBOARDING_IMAGES)[Page]
interface OnboardingImage {
  srcSet: string[]
  width?: number
  height?: number
  alt?: string
}

export interface OnboardingImageData {
  src: string
  srcSet: string
  width: number
  height: number
}

type OnboardingImageMap = {
  [key in OnboardingImageKeys]: {
    [image in OnboardingImages<key>]: OnboardingImage
  }
}
function getImage<T extends OnboardingImageKeys, I extends OnboardingImages<T>>(
  page: T,
  image: I,
  locale?: string
): OnboardingImage {
  const img = ONBOARDING_IMAGES[page][image] as OnboardingImage

  if (locale) {
    return {
      ...img,
      srcSet: img.srcSet.map((src) => src.replace('{{locale}}', locale)),
    }
  }

  return img
}

export function getImageProps<
  T extends OnboardingImageKeys,
  I extends OnboardingImages<T>,
>(page: T, image: I, locale?: string): OnboardingImageData {
  const target: OnboardingImage = getImage(page, image, locale)

  return {
    src: target?.srcSet[0] || '',
    srcSet: target?.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',') || '',
    width: target?.width || 0,
    height: target?.height || 0,
  }
}

export async function preloadImages(page: OnboardingImageKeys) {
  const map: {
    [key: string]: { [image: string]: OnboardingImage }
  } = ONBOARDING_IMAGES
  if (!map[page]) return
  const target = Object.values(map[page])
  return Promise.all(
    target.map((item) => {
      return new Promise((resolve, reject) => {
        const img = new Image(item.width, item.height)
        img.src = item.srcSet[0]
        img.srcset = item.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',')

        img.onload = resolve
        img.onerror = reject
      })
    })
  )
}

export const getLeadingImageProps = (
  leadingMediaType: 'photorealistic' | 'illustrated',
  key: keyof OnboardingImageMap['leadingImage'],
  gender: User.Profile.Gender
): OnboardingImageData => {
  const imageProps = getImageProps('leadingImage', key)

  return {
    ...imageProps,
    srcSet: imageProps.srcSet
      .replaceAll('{{gender}}', gender.toString())
      .replaceAll('{{leadingMediaType}}', leadingMediaType),
    src: imageProps.src
      .replaceAll('{{gender}}', gender.toString())
      .replaceAll('{{leadingMediaType}}', leadingMediaType),
  }
}
